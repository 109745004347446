<template>
  <div></div>
</template>

<script>
  export default {
    beforeCreate() {
      this.$router.push({
        name: "/error",
        params: { message: "We were unable to locate the requested resouce." },
      });
    },
  };
</script>
